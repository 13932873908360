import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/BlogPost.css';

function BlogPost({ post }) {
  const { id, title, author, date, description, banner } = post;

  return (
    <div className="blog-post">
      <div className="banner">
        <a href={`/blog/${id}`}> <img src={banner} alt="Banner" /></a>

      </div>
      <div className="post-content">
        <h2><Link to={`/blog/${id}`}>{title}</Link></h2>
        <p><b>Por:</b> {author}</p>
        <p><b>Data de publicação:</b> {date}</p>
        <p><i>{description}</i></p>
      </div>
    </div>
  );
}

export default BlogPost;
