import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { readPosts } from '../data/db';
import '../styles/Portfolio.css';
function Portfolio() {

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };

    return (
        <div className="portfolio">
            <h1>Conheça nossos projetos</h1>
            {readPosts().length > 0 ? (
                <Carousel responsive={responsive}>
                    {readPosts().filter((post) => post.category === 'portfolio').map((post, index) => (
                        <a href={`/blog/${post.id}`} key={index} className="carousel-item">
                            <img src={post.banner} alt={post.title} style={{ width: '80%', height: '150px' }} />
                            <h3>{post.title}</h3>
                            <p>{post.description}</p>
                        </a>
                    ))}
                </Carousel>
            ) : (
                <p>Carregando...</p>
            )}
        </div>
    );
}

export default Portfolio;