import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import BlogPage from './pages/BlogPage';
import BlogDetail from './pages/BlogDetail';
import AboutPage from './pages/AboutPage';
import AboutDetail from './pages/AboutDetail'
import PrivacyDetail from './pages/PrivacyDetail'
import TermsDetail from './pages/TermsDetail'
import './App.css'

function App() {
  return (
    <Router>
      <Header />
      <main>
        <Routes>
          <Route path="/" element={<HomePage/>} />
          <Route path="/new" element={<HomePage/>} />
          <Route path="/blog" element={<BlogPage/>} />
          <Route path="/blog/:id" element={<BlogDetail/>} />
          <Route path="/about" element={<AboutPage/>} />
          <Route path="/about/:id" element={<AboutDetail/>} />
          <Route path="/privacy" element={<PrivacyDetail/>} />
          <Route path="/privacy/:app" element={<PrivacyDetail/>} />
          <Route path="/terms" element={<TermsDetail/>} />
        </Routes>
      </main>
      <Footer />
    </Router>
  );
}

export default App;