import React, { useEffect, useState } from 'react';
import { readInfo } from '../data/db';
import '../styles/Footer.css';
import { Link } from 'react-router-dom';

function Footer() {

  const [companyInfo, setCompanyInfo] = useState({});

  useEffect(() => {
    setCompanyInfo(readInfo());
  }, []);

  return (
    <footer className="footer">
      <div className="footer-content">
        <p>{companyInfo.companyName} &copy; {new Date().getFullYear()}</p>
        <p><Link style={{ color: '#FFF' }} to="/privacy">Privacidade</Link> | <Link style={{ color: '#FFF' }} to="/terms">Termos de Uso</Link></p>
      </div>
    </footer>
  );
}

export default Footer;
