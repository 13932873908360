import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import '../styles/Contact.css';

const Contact = () => {
  const { register, handleSubmit, onSubmit } = useForm();
  const [selectedOption, setSelectedOption] = useState({ value: 'email', label: 'Email' });
  const [mensagem, setMensagem] = useState('Olá Queria mais informações sobre, poderia me ajudar?');

  const handleSubmitForm = async (data) => {

    if (selectedOption.value === 'email') {

      if (selectedOption.value === 'email') {
        const email = {
          subject: 'Contato - ' + data.nome,
          body: mensagem,
          to: 'support@connectifytech.com',
        };

        window.location.href = `mailto:${email.to}?subject=${encodeURIComponent(email.subject)}&body=${encodeURIComponent(email.body)}`;
      }

    } else if (selectedOption.value === 'whatsapp') {

      window.location.href = `https://wa.me/5533991915309?text=${encodeURIComponent(mensagem)}`;
    }
  };

  return (
    <div className="contact-description">
      <div className="contact-description-left">
        <h1>Entre em contato</h1>
        <p>Tentaremos responder o mais rápido possível!</p>
      </div>
      <div className="contact-description-right">
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <div className="form-group">
            <label htmlFor="select">Selecione a opção de contato:</label>
            <Select
              id="select"
              value={selectedOption}
              onChange={(option) => setSelectedOption(option)}
              options={[
                { value: 'email', label: 'Email' },
                { value: 'whatsapp', label: 'WhatsApp' },
              ]}

            />
          </div>
          {selectedOption.value === 'email' && (
            <>
              <div className="form-group">
                <label htmlFor="nome">Nome:</label>
                <input type="text" id="nome" {...register('nome')} required />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input type="email" id="email" {...register('email')} required />
              </div>
            </>
          )}
          <div className="form-group">
            <label htmlFor="mensagem">Mensagem:</label>
            {selectedOption.value === 'email' ?
              <textarea rows="6" id="mensagem" required value={mensagem} onChange={(e) => setMensagem(e.target.value)} />
              :
              <textarea rows="6" id="mensagem" value={mensagem} onChange={(e) => setMensagem(e.target.value)} />
            }
          </div>
          <button type="submit">Enviar</button>
        </form>
      </div>
    </div>
  );
};

export default Contact;