import postsData from './posts.json';
import infoData from './info.json';
import membersData from './members.json'

export const readMembers = () => {
    return membersData;
};

export const readPosts = () => {
    return postsData;
};

export const readInfo = () => {
    return infoData;
};