import React, { useEffect, useState } from 'react';
import { readInfo } from '../data/db';
import '../styles/Banner.css'; 
import { useLocation } from 'react-router-dom';

function Banner() {
  const [companyInfo, setCompanyInfo] = useState({});
  const location = useLocation();

  useEffect(() => {
    setCompanyInfo(readInfo());
  }, []);

  const pageTitle = location.pathname === "/new" || location.pathname === "/new/"  ? `One Host Apps agora é ${companyInfo.companyName}!` : `Bem-vindo à ${companyInfo.companyName}!`;

  return (
    <div className="banner-container">
      <div className="banner-content">
        <h1>{pageTitle}</h1>
        <p>{companyInfo.slogan}.</p>
      </div>
      <div className="banner-image-container">
        <img src="/images/astronaut.png" alt="Banner" />
      </div>
    </div>
  );
}

export default Banner;