import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { readMembers } from '../data/db';
import '../styles/AboutDetail.css';

function AboutDetail() {
  const { id } = useParams();
  const member = readMembers().find(member => member.id === parseInt(id));

  useEffect(() => {
    if (member) {
      document.title = `${member.name} - Sobre`;
      window.scrollTo(0, 0)
    }
  }, [member]);

  if (!member) {
    return (
      <div className="not-found-container">
        <img src="/images/notfound.png" alt="Not Found GIF" />
        <h2>Membro não encontrado</h2>
        <p>Desculpe, o membro que você está procurando não foi encontrado.</p>
      </div>
    );
  }

  const { name, position, degree, bio, image, date } = member;

  return (
    <div className="about-detail">
      <div className="member-info">
        <img src={image} alt={name} />
        <h1>{name}</h1>
        <p><b>Cargo:</b> {position}</p>
        <p><b>Nível:</b> {degree}</p>
        <p><b>Membro desde:</b> {date}</p>
      </div>
      <div className="member-bio">
        <h2>Sobre {name}</h2>
        <p>{bio}</p>
      </div>
    </div>
  );
}

export default AboutDetail;