import React, { useEffect, useState } from 'react';
import { readInfo } from '../data/db';
import { Link } from 'react-router-dom';
import '../styles/Header.css';

function Header() {
  const [companyInfo, setCompanyInfo] = useState({});

  useEffect(() => {
    setCompanyInfo(readInfo());
  }, []);

  return (
    <header className="header">
      <h1 className="logo">
        <Link to="/" className="logo-link">
          <img src="/logo_no_bg.png" alt="Logo" className="logo-img" />
          {companyInfo.companyName}
        </Link>
      </h1>

      <nav>
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/blog">Blog</Link></li>
          <li><Link to="/about">Sobre</Link></li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
