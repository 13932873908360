import React, { useEffect } from 'react';
import BlogPost from '../components/BlogPost'; 
import '../styles/BlogPage.css';
import { readPosts } from '../data/db';

function BlogPage() {
  const posts = readPosts(); 

  useEffect(() => {
    document.title = "Blog"
    window.scrollTo(0, 0)
  }, []);

  return (
    <div className="blog-page">
      <h1>Blog</h1>
      {posts.map(post => (
        <BlogPost key={post.id} post={post} />
      ))}
    </div>
  );
}

export default BlogPage;
